.footer {
  width: 100%;
  height: 56px;
  position: fixed;
  padding: 0;
  bottom: 0;

  .flexBox {
    height: 100%;
  }

  .flexItem {
    margin: 10px;
  }
}